import React from 'react';
import { Link, graphql, useStaticQuery } from "gatsby";

import ConsignmentStyles from "./Consignment.module.scss";
import { FaHandHoldingUsd, FaUsers, FaGraduationCap, FaMoneyBillAlt, FaInfoCircle, FaTwitter, FaEnvelope } from "react-icons/fa";

import packagingImageOne from "../images/packaging1.jpg";
import packagingImageTwo from "../images/packaging2.jpg";
import packagingImageThree from "../images/packaging3.jpg";

const Consignment = () => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    email
                    twitter
                }
            }
        }
    `);

    return (
        <section className={ConsignmentStyles.consignment} name="consignment">
            <div className="inner-container">
                <div className="section__title">
                    <h2>
                        <Link to="/" title={data.site.siteMetadata.title}><FaHandHoldingUsd /> {data.site.siteMetadata.title}</Link> &bull; Purchasing &amp; Consignment
                    </h2>
                </div>
                <div className={ConsignmentStyles.consignment__cardGroup}>
                    <div className={ConsignmentStyles.consignment__card}>
                        <FaUsers />
                        <h3>Maximum Exposure</h3>
                        <p>Get your collection in front of coin buyers, quickly and easily on eBay with Kick Ass Coins.</p>
                    </div>
                    <div className={ConsignmentStyles.consignment__card}>
                        <FaGraduationCap />
                        <h3>Knowledge is Key</h3>
                        <p>We educate you on what your collection consists of and the prices you can expect.</p>
                    </div>
                    <div className={ConsignmentStyles.consignment__card}>
                        <FaMoneyBillAlt />
                        <h3>All Profit, No Worry</h3>
                        <p>An all-inclusive service that allows you to profit from your collection, worry free.</p>
                    </div>
                </div>
                <div className={ConsignmentStyles.consignment__textGroup}>
                    <div className={ConsignmentStyles.consignment__text}>
                        <h3><FaInfoCircle /> Learn how we can help you!</h3>
                        <figure className="visible-mobile__block">
                            <img src={packagingImageThree} alt="Packaging Three" title="Packaged and shipped out to buyers immediately." />
                        </figure>
                        <p>Selling coins on your own can be confusing and overwhelming, so let Kick Ass Coins take care of that for you!</p>
                        <p>From the moment we receive your collection, it is inventoried, listed on eBay, then shipped immediately to buyers by Kick Ass Coins.</p>
                        <blockquote>We average <strong>125 unique visitors per day that spend over a half hour in the store at a time (consecutive clicks)</strong>. This kind of eBay exposure will ensure you of getting the best possible price for your coin.</blockquote>
                        <p>Each coin is carefully inspected, given a determination and grade, fully described including denomination, type, variety, and rarity.</p>
                        <p>We include most coins listed with detailed historical facts to give the buyer a sense of the times and make your coin stand apart from the rest.</p>
                        <p>All coins are photographed with top-notch equipment using proper angle and lighting (multiple photos for each coin) to bring out the best detail.</p>
                        <blockquote>Every coin is <a href={data.site.siteMetadata.twitter} title="@KickAssCoins on Twitter" rel="noopener noreferrer">Tweeted to all of our followers</a> the moment it becomes an active listing. <strong>Kick Ass Coins strives for excellent customer service and promptly answers all questions</strong> customers have about your collection.</blockquote>
                        <hr className="no-border visible-medium__block" />
                        <p>We carefully package and ship all of your coins to the purchaser immediately. Email or Tweet @ us to get started right away!</p>

                        <div className={ConsignmentStyles.consignment__cta}>
                            <a href="https://twitter.com/intent/tweet?screen_name=kickasscoins" title="Tweet at Kick Ass Coins" className="button btn--animated"><FaTwitter /> Tweet @KickAssCoins</a>
                            <a href={`mailto:${data.site.siteMetadata.email}?Subject=A Consignment inquiry from KickAssCoins.com`} title="Email Kick Ass Coins" rel="noopener noreferrer" className="button btn--animated"><FaEnvelope /> Email Kick Ass Coins</a>
                        </div>
                    </div>
                    <aside className={ConsignmentStyles.consignment__aside}>
                        <figure>
                            <img src={packagingImageOne} alt="Packaging One" title="Carefully inspected; given a determination and grade; and fully described." />
                            <figcaption>Carefully inspected; given a determination and grade; and fully described.</figcaption>
                        </figure>
                        <figure>
                            <img src={packagingImageThree} alt="Packaging Three" title="Packaged and shipped out to buyers immediately." />
                            <figcaption>Packaged and shipped out to buyers immediately.</figcaption>
                        </figure>
                        <figure>
                            <img src={packagingImageTwo} alt="Packaging Two" title="Get top dollar for your collection." />
                            <figcaption>Get top dollar for your collection.</figcaption>
                        </figure>
                    </aside>
                </div>
            </div>
        </section>
    );
};

export default Consignment;