import React from "react";
import Layout from "../components/Layout";

import Head from "../components/SiteHead";

import Consignment from "../components/Consignment";
import Showcase from "../components/Showcase";
import Collect from "../components/Collect";

const IndexPage = () => {
    return (
        <Layout>
            <Head title="Home" />
            <Consignment />
            <Showcase />
            <Collect />
        </Layout>  
    );
};

export default IndexPage;
