import React from 'react';
import { Link, graphql, useStaticQuery } from "gatsby";

import CollectStyles from "./Collect.module.scss";
import { FaCoins, FaFilePdf, FaUserShield } from "react-icons/fa";

import ebookImg from "../images/ebook-img.png";

const Collect = () => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <section className={CollectStyles.collect} name="collect">
            <div className="inner-container">
                <div className="section__title">
                    <h2>
                        <Link to="/" title={data.site.siteMetadata.title}><FaCoins /> {data.site.siteMetadata.title}</Link> &bull; Collect Like a Pro
                    </h2>
                </div>
                <h3 className={CollectStyles.collect__title}>Learn to collect like a Numismatist with our free ebook</h3>
                <div className={CollectStyles.collect__ctaContainer}>
                    <div className={CollectStyles.collect__ctaSecondary}>
                        <ul className={CollectStyles.collect__quoteList}>
                            <li>"The bottom line is, when investing in rare coins, quality trumps quantity."</li>
                            <li>"...learn how coins are graded and valued or you will become an easy target for unscrupulous coin dealers that take advantage of the beginner."</li>
                            <li>"...high-grade, extremely rare coins bring in the highest dollar amounts, but the pool of investors is also extremely limited."</li>
                        </ul>
                    </div>
                    <div className={CollectStyles.collect__ctaPrimary}>
                        <figure>
                            <img src={ebookImg} alt="Coin Collecting Essentials eBook" />
                        </figure>
                        <div className={CollectStyles.collect__ctaPrimaryMain}>
                            <p>Learn the skills required to be a smart, profitable, and careful collector by signing up for our free PDF!</p>
                            <a className="button" href="https://forms.gle/dYba7ZsWDcjWgBhg7" title="Use Google Forms to sign up for the Free Kick Ass Coins eBook" rel="noopener noreferrer"><FaFilePdf /> Get your FREE ebook</a>
                        </div>
                    </div>
                    <div className={CollectStyles.collect__ctaSecondary}>
                        <ul className={CollectStyles.collect__quoteList}>
                            <li>"Solid gold is not magnetic...alloys can be added that make it become magnetic. The higher the carat, the less magnetic. Acid is the best way to test for gold."</li>
                            <li>"If you’re looking for quality at a good price, stick with dealers that are enthusiastic about their coins..."</li>
                            <li>"The obsolete 2 or 3 cent pieces from the Civil War era are always fun to collect and won’t break the bank."</li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div className={CollectStyles.collect__privacy}>
                    <h3><FaUserShield /> Privacy Policy</h3>
                    <p>At Kick Ass Coins we respect people's privacy. <strong>We will never sell your email address or other personal information to any third parties</strong>. Kick Ass Coins wants you to know that by entering your email address you agree to receive occasional promotional emails from us.</p>
                </div>
            </div>
        </section>
    );
};

export default Collect;